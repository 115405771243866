import { Col, Row, TagField } from "@pankod/refine-antd";
import { mask } from "./mask";
import { formatDate } from "./dateFormat";
import { StatusType, UserRole } from "enums";
import { SelectOption, IPayment } from "interfaces";
import { convertToBRL } from "./decimalToBRLcurrency";

export function handleAllStatuses(values: string[]): JSX.Element {
  const statusesInfo = values
    .map(value => handleStatusInfo(value))
    .sort((statusA, statusB) => statusA.name.localeCompare(statusB.name));

  const tagFields = statusesInfo.map((statusInfo) => {
    return <TagField color={statusInfo.color} value={statusInfo.name} />;
  });

  return (
    <Row gutter={[10, 10]}>
      {tagFields.map((tagField, index) => (
        <Col key={index} xs={24} sm={24}>
          {tagField}
        </Col>
      ))}
    </Row>
  );
};

export function handleStatus(value: string): JSX.Element {
  const statusInfo = handleStatusInfo(value);

  return <TagField color={statusInfo.color} value={statusInfo.name} />;
};

export function handleAllOperationType(values: string[]): string {
  return values.join('/');
};

export function handleFiscalId(fiscalId: string): JSX.Element {
  const masked = fiscalId.replace(/[^\d]/g, "").length === 11
    ? mask(fiscalId.replace(/[^\d]/g, "") ?? '', '###.###.###-##')
    : mask(fiscalId.replace(/[^\d]/g, "") ?? '', '##.###.###/####-##')
  return (<div style={{ whiteSpace: 'nowrap' }}>{masked}</div>)
}

export function handleDate(date: string): string | undefined {
  return formatDate(date);
}

export function handleAccountBalance(value: string, record: IPayment): string {
  const statusRejected = [StatusType.PAID, StatusType.REVERSAL, StatusType.PROCESSING] as String[];
  return value && !statusRejected.includes(record.status) ? (`${convertToBRL(value)}`) : '****';
}

function handleStatusInfo(value: string): {
  color: string;
  name: string;
} {

  const mapStatusToInfo: { [key: string]: { color: string, name: string } } = {
    paid: { color: 'green', name: 'Pago' },
    reversal: { color: 'purple', name: 'Estornado' },
    error: { color: 'red', name: 'Erro' },
    unavailable: { color: 'gray', name: 'Indisponível' },
    processing: { color: 'blue', name: 'Processando' },
  };

  const defaultStatusInfo = { color: 'yellow', name: 'Pendente' };
  const statusInfo = mapStatusToInfo[value] || defaultStatusInfo;

  return statusInfo;
};

export function handleUserRole(value: string): JSX.Element {

  const userRole = value as UserRole;

  switch (userRole) {
    case UserRole.ADMIN:
      return <TagField color={'purple'} value={'Admin'} />;
    case UserRole.MANAGER:
      return <TagField color={'blue'} value={'Gerenciador'} />;
    case UserRole.VIEWER:
      return <TagField color={'yellow'} value={'Visualizador'} />;
    case UserRole.OPERATOR:
      return <TagField color={'pink'} value={'Operador'} />;
    case UserRole.APPROVER:
      return <TagField color={'green'} value={'Aprovador'} />;
    default:
      return <div>****</div>;
  }
};

export function handleBank(bankOptions: SelectOption[], value: string | undefined): string {
  if (value) {
    const foundBank = bankOptions.find(bank => bank.value === value);

    if (foundBank) {
      return foundBank.label;
    } else {
      return value;
    }
  } else {
    return '****';
  }
}
