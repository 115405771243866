import { FormProps, Form, Row, Col, Input, Icons, Select, DatePicker, Button } from "@pankod/refine-antd";
import locale from 'antd/es/date-picker/locale/pt_BR';

const PaymentFilter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form {...formProps} layout='vertical'>
            <Row gutter={[8,0]}>
                <Col xs={{span: 24}} sm={{span: 24}} xl={{span: 6}} >
                    <Form.Item label="Buscar" name="q">
                        <Input
                            placeholder="ID, Nome, CPF, etc."
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} sm={{span: 10}} xl={{span: 6}} >
                    <Form.Item label="Status" name="status">
                        <Select
                            allowClear
                            options={[
                                {
                                    label: "Pendente",
                                    value: "pending",
                                },
                                {
                                    label: "Processando",
                                    value: "processing",
                                },
                                {
                                    label: "Pago",
                                    value: "paid",
                                },
                                {
                                    label: "Estornado",
                                    value: "reversal",
                                },
                                {
                                    label: "Indisponivel",
                                    value: "unavailable",
                                },
                                {
                                    label: "Erro",
                                    value: "error",
                                },
                            ]}
                            placeholder="Status de pagamento"
                            mode='multiple'
                        />
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} sm={{span: 14}} xl={{span: 6}} >
                    <Form.Item label="Data" name="createdAt">
                        <DatePicker.RangePicker locale={locale} format={'DD/MM/YYYY'} />
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} sm={{span: 24}} xl={{span: 6}} style={{display: 'flex', alignItems: 'flex-end'}}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Filtrar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

const UserFilter: React.FC<{ 
    formProps: FormProps, 
    userOptions: { label: string; value: string; }[]
}> = ({ formProps, userOptions }) => {
    return (
        <Form {...formProps} layout='vertical'>
            <Row gutter={[8, 0]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 7 }} >
                    <Form.Item label="Buscar" name="q">
                        <Input
                            placeholder="E-mail, Nome, Bank ID, etc."
                            prefix={<Icons.SearchOutlined />}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 5 }} >
                    <Form.Item label="Tipo de usuário" name="roles">
                        <Select
                            allowClear
                            options={userOptions}
                            placeholder="Selecione o tipo de usuário"
                            mode='multiple'
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 6 }} style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Filtrar
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export { PaymentFilter, UserFilter }