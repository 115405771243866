import { OpenNotificationParams } from "@pankod/refine-core";
import axios from "axios";

export function buildErrorNotification(error: any, defaultMessage: string): OpenNotificationParams {
    const { status, message } = axios.isAxiosError(error) ? error.toJSON() : error; 
    const { request } = error;

    if (message === "Network Error") {
        return {
            description: `Problemas de conexão, sua internet ou servidor está fora do ar`,
            message: `Não foi possível conectar-se ao servidor!`,
            type: "error",
        };
    } else if (status === 403) {
        return {
            message: "Você não pode acessar esta funcionalidade!",
            type: "error",
        };
    } else if (!request || !request.response) {
        return {
            message: `${defaultMessage}`,
            description: ``,
            type: "error",
        };
    }

    const jsonObject = JSON.parse(request.response);

    const errorMessage = jsonObject.message ?? request.response;

    const jsonMessage = JSON.stringify(errorMessage,null,2);

    return {
        message: `${defaultMessage}`,
        description: `Erros: ${jsonMessage}`,
        type: "error",
    };
}