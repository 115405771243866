export enum OperationType {
    PIX = 'pix',
    TED = 'ted',
    QRCODEPIX = 'pixqrcode',
    BOLETO = 'boleto'
}

export enum StatusType {
    PAID = 'paid',
    PENDING = 'pending',
    REVERSAL = 'reversal',
    ERROR = 'error',
    UNAVAILABLE = 'unavailable',
    PROCESSING = 'processing'
}

export enum UserRole {
    ADMIN = 'admin',
    MANAGER = 'manager',
    VIEWER = 'viewer',
    OPERATOR = 'operator',
    APPROVER = 'approver',
}

export enum ReportConfig {
    PAYMENT = 'payment',
    USER = 'user'
}