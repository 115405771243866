import React, { useEffect, useState } from 'react';
import { IPayment, IPaymentApproval, IUser } from 'interfaces';
import { DollarOutlined } from "@ant-design/icons";
import { Button, Modal, Table, useModal, TextField, notification } from '@pankod/refine-antd';
import { useApiUrl, useCustomMutation, useGetIdentity } from '@pankod/refine-core';
import { fiscalIdMask } from '../../../utils/mask';
import { UserRole } from 'enums';

type Props = {
  data?: IPayment[],
  onStart?: () => void,
  onFinish?: () => void,
}

interface IPaymentDetails {
  key: string,
  value: string,
  receiverFiscalId: string,
  receiverName: string
}

interface IPaymentSummary {
  [payerName: string]: {
    total: number,
    details: IPaymentDetails[];
  };
}

interface IFormattedPaymentSummary {
  key: string
  name: string;
  value: string;
  details: IPaymentDetails[];
}

const PaymentSummaryModal: React.FC<Props> = ({ data, onStart, onFinish }: Props) => {
  const { modalProps, show } = useModal({ modalProps: {} });
  const [dataSource, setDataSource] = useState<IFormattedPaymentSummary[]>([]);
  const [hiddenBtnPayment, setHiddenBtnPayment] = useState<boolean>(true);
  const [canApprovePayment, setCanApprovePayment] = useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (data) {
      const formattedData = generateSummary(data);
      setDataSource(formattedData);
    }

    if (user) {
      const userRole = (user?.role as UserRole);
      setCanApprovePayment([UserRole.ADMIN, UserRole.MANAGER, UserRole.APPROVER].includes(userRole));
    }

    setHiddenBtnPayment(!canApprovePayment);
  }, [canApprovePayment, data, user])

  const {
    mutate,
    isLoading: payManyIsLoading
  } = useCustomMutation<IPaymentApproval>();

  const apiUrl = useApiUrl();

  const generateSummary = (data: IPayment[]): IFormattedPaymentSummary[] => {

    const sums: IPaymentSummary = {};

    for (const item of data) {

      if (!sums[item.payerName]) {
        sums[item.payerName] = {
          total: 0,
          details: []
        };
      }

      sums[item.payerName].total += parseFloat(item.dueAmount);

      sums[item.payerName].details.push({
        key: `${item?.id}${Date.now()}`,
        receiverFiscalId: fiscalIdMask(item?.receiverFiscalId || ''),
        receiverName: item?.receiverName.toUpperCase() || '',
        value: Number(item?.dueAmount)?.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) || '',

      })
    }

    const formattedPaymentSummary: IFormattedPaymentSummary[] = [];

    const formatted = Object.entries(sums).map(([indexName, record]) => ({
      key: `${indexName.replace(' ', '')}${Date.now()}`,
      value: record.total.toFixed(2),
      name: indexName,
      details: record.details
    }))

    Object.assign(formattedPaymentSummary, formatted);

    const total = Object.values(sums).reduce((acc, current) => acc + current.total, 0).toFixed(2)

    formattedPaymentSummary.push({ key: 'total', name: 'total', value: total, details: [] });

    return formattedPaymentSummary;
  }

  const handleColumnNameRender = (item: string) => {

    if (item === 'total') {
      return (
        <TextField
          style={{
            fontWeight: 'bold',
          }}
          value={item.toUpperCase()}
        />
      )
    }

    return item.toUpperCase();
  }

  const componentDetails = (details: IPaymentDetails[]) => {

    const formatted = details.map(detail => {
      return {
        ...detail,
        receiverFiscalId: fiscalIdMask(detail.receiverFiscalId)
      }
    })

    return (
      <Table
        columns={[
          { key: 'receiverName', title: 'Nome destinario', dataIndex: 'receiverName' },
          { key: 'receiverFiscalId', title: 'CPF/CNPJ destino', dataIndex: 'receiverFiscalId' },
          { key: 'value', title: 'Valor', dataIndex: 'value' },
        ]}
        size='small'
        dataSource={formatted}
        pagination={false}
        footer={() => ''}
        bordered={false}
      />
    )
  }

  const paySelectedItems = () => {

    if (onStart) onStart();

    if (!canApprovePayment) {
      if (onFinish) onFinish();
      return
    }

    const headers = { Authorization: `Bearer ${token}` };

    const ids = data?.map(item => String(item.id));

    mutate({
      url: `${apiUrl}/payment/approve`,
      method: 'post',
      config: { headers },
      values: { ids },
    }, {
      onSettled(result) {
        if (onFinish) onFinish();

        if (!result) return;

        const { successes, failures } = result.data;

        successes?.forEach(id => {
          notification.open({
            type: 'success',
            message: `Processando pagamento ID: ${id}`
          });
        }); 

        failures?.forEach(id => {
          notification.open({
            type: 'error',
            message: `Erro ao processar o pagamento ID: ${id}`
          })
        });
      }
    });
  };

  return (
    <>
      <Button
        icon={<DollarOutlined />}
        onClick={show}
      >
        Pagamentos
      </Button>

      <Modal
        {...modalProps}
        width={800} title='Resumo de pagamentos'
        onOk={paySelectedItems}
        okText={payManyIsLoading ? 'Confirmando...' : 'Confirmar pagamento'}
        okButtonProps={{ loading: payManyIsLoading, hidden: hiddenBtnPayment }}
        cancelButtonProps={{ hidden: payManyIsLoading }}
      >
        <Table
          showHeader={false}
          pagination={false}
          dataSource={dataSource}
          size='small'
          expandable={{
            expandedRowRender: (item => componentDetails(item.details)),
            rowExpandable: (item => item.name !== 'total'),
          }}

        >
          <Table.Column dataIndex={"name"} title="Nome" render={(value: string) => handleColumnNameRender(value)} />
          <Table.Column dataIndex={"value"} align='right' title="Valor" render={(value: string) => Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} />
        </Table>
      </Modal>
    </>
  );
}

export default PaymentSummaryModal;