import React from 'react';
import { Button } from 'antd';
import { Popconfirm } from '@pankod/refine-antd';
import { DeleteOutlined } from "@ant-design/icons";

interface CustomDeleteButtonProps {
  body: number[];
  handleDelete: () => {};
  style?: React.CSSProperties | undefined;
}

class CustomDeleteButton extends React.Component<CustomDeleteButtonProps> {

  render() {
    return (
      <>
        <Popconfirm
          title="Você tem certeza?"
          okText="Excluir"
          cancelText="Cancelar"
          onConfirm={this.props.handleDelete}
        >
          <Button
            danger
            style={this.props.style}
            icon={<DeleteOutlined />}
          >
            Excluir
          </Button>
        </Popconfirm>
      </>
    );
  }
}

export default CustomDeleteButton;
