import React, { useState } from "react";
import { useLogin } from "@pankod/refine-core";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Button,
} from "@pankod/refine-antd";
import "./styles.css";
import { LoginFormItems, DefinePassFormItems } from "components/login/form";

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
}

export interface IDefinePassForm {
  username: string;
  tempPassword: string;
  newPassword: string;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm | IDefinePassForm>();

  const { mutate: login } = useLogin<ILoginForm | IDefinePassForm>();

  const [isRedefinePassword, setIsRedefinePassword] = useState<boolean>();

  const title = isRedefinePassword
    ? "Defina sua nova senha"
    : "Entre com sua conta";

  const [isLogging, setIsLogging] = useState<boolean>();

  const CardTitle = (
    <Title level={3} className="title">
      {title}
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img src="./cartos.svg" alt="Cartos" />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm | IDefinePassForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  setIsLogging(true);
                  login(values, {
                    onSettled: async (result) => {
                      setIsLogging(false);
                      console.log(result);
                      if (result === false) {
                        setIsRedefinePassword(true);
                      }
                    },
                  });
                }}
                requiredMark={false}
              >
                {isRedefinePassword ? DefinePassFormItems : LoginFormItems}

                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                  loading={isLogging}
                  color="blue"
                >
                  Entrar
                </Button>

                {isRedefinePassword && (
                  <Button
                    type="default"
                    size="large"
                    htmlType="button"
                    block
                    disabled={isLogging}
                    onClick={() => setIsRedefinePassword(false)}
                    style={{ marginTop: "12px" }}
                  >
                    Cancelar
                  </Button>
                )}
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
