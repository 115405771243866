import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { CustomerServiceOutlined, DollarOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";

import { authProvider, axiosInstance } from "./authProvider";
import { PaymentList } from "pages/payments";
import { Login } from "pages/login";
import { CustomSider } from "components/sider";
import { UserProfileList } from "pages/user-profile";
import { UsersList } from "pages/users";
import { BankStateProvider } from "context";
import { SupportList } from "pages/support";

const { Link } = routerProvider;

function App() {
  return (
    <Refine
      authProvider={authProvider}
      notificationProvider={notificationProvider}
      Layout={(props) => (
        <BankStateProvider>
          <Layout {...props} />
        </BankStateProvider>
      )}
      ReadyPage={ReadyPage}
      Sider={CustomSider}
      LoginPage={Login}
      catchAll={<ErrorComponent />}
      routerProvider={{
        ...routerProvider,
      }}
      Title={({ collapsed }) => (
        <Link to="/">
          {collapsed ? (
            <img
              src="/cartos-collapsed.svg"
              alt="Cartos"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "12px 24px",
              }}
            />
          ) : (
            <img
              src="/cartos.svg"
              alt="Cartos"
              style={{
                width: "200px",
                padding: "12px 24px",
              }}
            />
          )}
        </Link>
      )}
      dataProvider={dataProvider(
        process.env.REACT_APP_API_URL ?? "",
        axiosInstance,
      )}
      resources={[
        {
          name: "payment",
          list: PaymentList,
          canDelete: true,
          options: { label: "Pagamentos" },
          icon: <DollarOutlined />,
        },
        {
          name: "users",
          list: UsersList,
          canDelete: true,
          options: { label: "Usuários" },
          icon: <TeamOutlined />,
        },
        {
          name: "user-profile",
          list: UserProfileList,
          canDelete: true,
          options: { label: "Perfil" },
          icon: <UserOutlined />,
        },
        {
          name: "support",
          list: SupportList,
          canDelete: true,
          options: { label: "Suporte" },
          icon: <CustomerServiceOutlined />,
        },
      ]}
    />
  );
}

export default App;
