import { Divider, Drawer, DrawerProps, Form, FormProps, RcFile, Upload, getValueFromEvent, notification } from "@pankod/refine-antd";
import React from "react";
import { validationFile } from "utils/validationFile";

interface ImportPaymentDrawerProps {
  createDrawerProps: DrawerProps,
  createFormProps: FormProps,
  apiURL: string,
  bankId: string,
  onChange: (info: any) => Promise<void>
}

class ImportPaymentDrawer extends React.Component<ImportPaymentDrawerProps> {

  beforeUpload(file: RcFile): string | boolean {
    const { isValid, msg } = validationFile(file);

    if (!isValid) notification.error({ message: msg, duration: null });

    return isValid || Upload.LIST_IGNORE;
  }

  handleAuthHeader() {
    const token = localStorage.getItem('token');
    return {
      Authorization: `Bearer ${token}`,
      bankid: `${this.props.bankId}`,
    };
  }

  render() {

    const {
      createDrawerProps,
      createFormProps,
      apiURL,
      onChange
    } = this.props;

    return (
      <Drawer {...createDrawerProps} closable={true}  >
        <Form {...createFormProps} layout="vertical">
          <Form.Item label="Importar Pagamentos">
            <Form.Item
              name="fileForm"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              noStyle
              rules={[{ required: true, message: "É necessario inserir um arquivo" }]}
            >
              <Upload.Dragger
                name="file"
                beforeUpload={this.beforeUpload}
                action={`${apiURL}/payment/import`}
                method='POST'
                headers={this.handleAuthHeader()}
                onChange={onChange}
                listType="text"
                maxCount={1}
                multiple
                height={200}
              >
                <p className="ant-upload-text">
                  Arraste e solte um arquivo nesta área
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Divider>Templates</Divider>
          <Form.Item>
            <a href={`${process.env.REACT_APP_API_URL}/payment/bankdata/template?auth=sdwe%403qwqja!we`} rel="noreferrer" target='_blank'>PIX / TED Dado bancário</a>
          </Form.Item>
          <Form.Item>
            <a href={`${process.env.REACT_APP_API_URL}/payment/qrcode/template?auth=sdwe%403qwqja!we`} rel="noreferrer" target='_blank'>QRCODE PIX</a>
          </Form.Item>
          <Form.Item>
            <a href={`${process.env.REACT_APP_API_URL}/payment/boleto/template?auth=sdwe%403qwqja!we`} rel="noreferrer" target='_blank'>BOLETO</a>
          </Form.Item>
        </Form>
      </Drawer>
    );
  }
}

export default ImportPaymentDrawer;