import { useIsExistAuthentication } from "@pankod/refine-core";
import { axiosInstance } from "authProvider";
import { Bank, SelectOption } from "interfaces";
import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

interface IBankState {
  bankId: string;
  setBankId: React.Dispatch<string>;
  bankOptions: SelectOption[];
}

export const BankStateContext = createContext<IBankState>(null!);

export function BankStateProvider({ children }: { children: ReactNode }) {
  const defaultBankId = process.env.DEFAULT_BANK_ID || "007";

  const [bankId, setBankId] = useReducer((_: any, newBank: string) => {
    const headers = axiosInstance.defaults.headers.common;

    if (newBank) {
      headers["bankId"] = newBank;
    } else {
      delete headers["bankId"];
    }

    return newBank;
  }, defaultBankId);

  const [bankOptions, setBankOptions] = useState<SelectOption[]>([]);

  const isAuthenticated = useIsExistAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    axiosInstance.defaults.headers.common["bankId"] = defaultBankId;
    axiosInstance
      .get<Bank[]>("/bank")
      .then((response) => response.data)
      .then((banks) => {
        if (!banks) {
          return;
        }

        const bankOptions: SelectOption[] = banks.map((bank) => ({
          label: `${bank.externalBankId} - ${bank.name}`,
          value: bank.externalBankId,
        }));

        setBankOptions(bankOptions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [defaultBankId, isAuthenticated]);

  const value = useMemo(
    () => ({
      bankId,
      setBankId,
      bankOptions,
    }),
    [bankId, setBankId, bankOptions],
  );

  return (
    <BankStateContext.Provider value={value}>
      {children}
    </BankStateContext.Provider>
  );
}
