import {
  Table,
  useTable,
  Space,
  DeleteButton,
  useDrawerForm,
  Button,
  notification,
  RefreshButton,
  Checkbox,
  Modal,
  FilterDropdown,
  Input,
  Select,
  DatePicker,
} from "@pankod/refine-antd";
import {
  EyeOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  HttpError,
  useApiUrl,
  useCheckError,
  useCustom,
  useCustomMutation,
  useGetIdentity,
  useShow,
} from "@pankod/refine-core";
import { IPayment, IUser } from "interfaces";
import Voucher from "components/voucherTemplate";
import { useState, useEffect, useContext } from "react";

import "dayjs/locale/pt-br";
import { ReportConfig, StatusType, UserRole } from "enums";
import { convertToBRL } from "utils/decimalToBRLcurrency";
import PaymentSummaryModal from "../../components/payment/summary";
import { REPORT_MAX_LINES, ReportGenerator } from "utils/reportGenerator";
import CustomDeleteButton from "components/buttons";
import { PaymentShow } from "../../components/payment/show";
import ImportPaymentDrawer from "components/payment/import";
import { CustomList, TwoSections } from "components/template";
import {
  handleFiscalId,
  handleStatus,
  handleDate,
  handleAccountBalance,
} from "utils/tableFunctions";

import locale from "antd/es/date-picker/locale/pt_BR";
import { FileErrorsModal } from "components/payment/errors";
import { buildErrorNotification } from "utils/errorValidation";
import { BankStateContext } from "context";
import { axiosInstance } from "authProvider";
import { HeaderPage } from "components/header";

export const PaymentList: React.FC = () => {
  const { bankId, setBankId, bankOptions } = useContext(BankStateContext);

  const [isAdmin, setIsAdmin] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<IPayment[]>([]);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [isAllPending, setIsAllPending] = useState<boolean>(false);
  const [canDeleteMany, setCanDeleteMany] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [isRefreshingBalance, setIsRefreshingBalance] =
    useState<boolean>(false);
  const [visiblePaymentShowModal, setVisiblePaymentShowModal] =
    useState<boolean>(false);
  const [fileErrorsMessages, setFileErrorsMessages] = useState<string[]>([]);
  const [visibleFileErrorsMessages, setVisibleFileErrorsMessages] =
    useState<boolean>(false);

  const { data: user } = useGetIdentity<IUser>();
  const [canCreatePayment, setCanCreatePayment] = useState<boolean>(false);
  const [canDeletePayment, setCanDeletePayment] = useState<boolean>(false);

  const balance = useCustomMutation<IPayment>();
  const { mutate: checkError } = useCheckError();

  const apiUrl = useApiUrl();

  const {
    tableProps,
    sorter,
    filters,
    tableQueryResult: { refetch: refetchPaymentTable },
  } = useTable<IPayment, HttpError>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagamentos!",
      );
    },
  });

  const getPaymentsForReport = useCustom<IPayment[]>({
    url: "/payment",
    method: "get",
    config: {
      filters,
      sort: sorter,
      query: { _start: 0, _end: REPORT_MAX_LINES },
    },
    queryOptions: { enabled: false },
    errorNotification(error) {
      return buildErrorNotification(
        error,
        "Não foi possível acessar os pagamentos!",
      );
    },
  });

  const report = new ReportGenerator(ReportConfig.PAYMENT);

  useEffect(() => {
    setSelectedRowKeys([]);

    if (user) {
      const userRole = user.role as UserRole;
      setIsAdmin(userRole === UserRole.ADMIN);
      setCanCreatePayment(
        [UserRole.ADMIN, UserRole.OPERATOR, UserRole.MANAGER].includes(
          userRole,
        ),
      );
      setCanDeletePayment(
        [
          UserRole.ADMIN,
          UserRole.MANAGER,
          UserRole.APPROVER,
          UserRole.OPERATOR,
        ].includes(userRole),
      );
    }
  }, [user]);

  const onSelectChange = (
    selectedRowKeys: React.Key[],
    selectedRows: IPayment[],
  ) => {
    const availableRows = selectedRows.filter(
      (payment) =>
        ![
          StatusType.REVERSAL,
          StatusType.PROCESSING,
          StatusType.ERROR,
        ].includes(payment.status as StatusType),
    );

    const firstSelected = selectedRowKeys[0];

    const firstSelectedHasPaid = availableRows.some(
      (payment) =>
        payment.status === StatusType.PAID &&
        payment.id === Number(firstSelected),
    );

    const paidIds = availableRows
      .filter((payment) => payment.status === StatusType.PAID)
      .map((payment) => payment.id);

    const finalPayments = availableRows.filter((value) =>
      firstSelectedHasPaid
        ? paidIds.includes(Number(value.id))
        : !paidIds.includes(Number(value.id)),
    );

    const allSelectedHasPaid = finalPayments.every(
      (payment) => payment.status === StatusType.PAID,
    );

    const allSelectedHasPending = finalPayments.every(
      (payment) => payment.status === StatusType.PENDING,
    );

    const availableToDelete = finalPayments.every((payment) =>
      [StatusType.PENDING, StatusType.UNAVAILABLE].includes(
        payment.status as StatusType,
      ),
    );

    setIsPdf(allSelectedHasPaid);
    setIsAllPending(allSelectedHasPending);
    setCanDeleteMany(availableToDelete && canDeletePayment);
    setSelectedRowData(finalPayments);
    setSelectedRowKeys(finalPayments.map((payment) => payment.id));
  };

  const handleCheckboxRender = (
    _value: boolean,
    record: IPayment,
    _index: number,
    nodeOriginal: any,
  ) => {
    const disabledStatus: string[] = [
      StatusType.REVERSAL,
      StatusType.PROCESSING,
      StatusType.ERROR,
    ];

    if (disabledStatus.includes(record.status)) {
      return <Checkbox disabled />;
    }

    return nodeOriginal;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    renderCell: handleCheckboxRender,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const {
    formProps: importPaymentsFormProps,
    drawerProps: importPaymentsDrawerProps,
    show: showImportPayments,
    close: closeImportPayments,
    form: importPaymentsForm,
  } = useDrawerForm<IPayment[]>({
    action: "create",
  });

  const onSelectPaymentImportFile = async (info: any) => {
    const status = info.file.status;

    switch (status) {
      case "done":
        notification.success({
          message: "Pagamentos importados com Sucesso",
        });
        closeImportPayments();
        await refetchPaymentTable();
        importPaymentsForm.resetFields();
        break;
      case "error":
        checkError(info.file.response);
        const errorMessage = info.file.response?.message ?? info.file.response;
        const errorStatus =
          info.file.response?.status ?? info.file.response?.statusCode;

        if (errorStatus === 401) {
          break;
        }

        if (!Array.isArray(errorMessage) || errorMessage.length <= 1) {
          const formmatedMessage = Array.isArray(errorMessage)
            ? errorMessage[0]
            : errorMessage;
          const jsonMessage = JSON.stringify(formmatedMessage, null, 2).replace(
            /"/g,
            "",
          );
          notification.error({
            message: `1 Erro encontrado`,
            description: jsonMessage,
            duration: null,
          });
          break;
        }

        const allErrors = errorMessage.map((msg: any) => {
          const jsonMessage = JSON.stringify(msg, null, 2);
          return jsonMessage.replace(/"/g, "");
        });

        const notificationClick = () => {
          setFileErrorsMessages(allErrors);
          setVisibleFileErrorsMessages(true);
          notification.close("error-modal");
        };

        notification.error({
          message: `${allErrors.length} Erros encontrados`,
          description: `${allErrors[0]} [...]`,
          duration: null,
          key: "error-modal",
          btn: (
            <Space>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  notificationClick();
                }}
              >
                {"Ver Todos >>"}
              </Button>
            </Space>
          ),
        });
        break;

      default:
        break;
    }
  };

  const handleRefreshBalance = async () => {
    if (isRefreshingBalance) return;

    setIsRefreshingBalance(true);

    try {
      setSelectedRowKeys([]);
      setIsTableLoading(true);

      await balance.mutateAsync({
        url: "/payment/updatebalance",
        method: "post",
        values: "",
        successNotification() {
          return { message: "Tabela atualizado", type: "success" };
        },
      });

      await refetchPaymentTable();
    } catch (error) {
      checkError(error);
      const notificationParams = buildErrorNotification(
        error,
        "Não foi possivel atualizar a tabela",
      );
      notification.error({
        message: notificationParams.message,
        description: notificationParams.description,
      });
    } finally {
      setIsTableLoading(false);
      setIsRefreshingBalance(false);
    }
  };

  const handleGenerateReport = async () => {
    const filterParams = (filters || []).reduce(
      (obj, crudFilter) => {
        if ("field" in crudFilter) {
          obj[crudFilter.field] = crudFilter.value;
        }

        return obj;
      },
      {} as Record<string, any>,
    );

    const getData = async () => {
      const { data: items } = await getPaymentsForReport.refetch();
      const payments = items?.data;

      if (!payments || payments.length === 0) {
        throw new Error("Não foi possível gerar o relatório");
      }

      return payments;
    };

    try {
      report.showModal(getData, filterParams, setIsReportLoading);
    } catch (error) {
      checkError(error);
    }
  };

  const ShowPaymentModalButton = (props: { recordItemId: number }) => {
    const handleButtonClick = () => {
      setShowId(props.recordItemId);
      setVisiblePaymentShowModal(true);
    };

    return (
      <Button
        size="small"
        type="default"
        icon={<EyeOutlined />}
        onClick={handleButtonClick}
      />
    );
  };

  const { queryResult, setShowId } = useShow<IPayment>();

  const { data: showQueryResult, isLoading: showIsLoading } = queryResult;
  const record = showQueryResult?.data;

  const ShowPaymentModal: JSX.Element = (
    <Modal
      visible={visiblePaymentShowModal}
      width={1200}
      onCancel={() => {
        setVisiblePaymentShowModal(false);
      }}
      footer={null}
    >
      <PaymentShow record={record} isLoading={showIsLoading} />
    </Modal>
  );

  const handleActions = (_text: any, record: IPayment): React.ReactNode => {
    const onlyShowView = [
      StatusType.PAID,
      StatusType.REVERSAL,
      StatusType.PROCESSING,
      StatusType.ERROR,
    ];

    if (onlyShowView.includes(record.status as StatusType)) {
      return (
        <Space>
          <ShowPaymentModalButton recordItemId={record.id} />
        </Space>
      );
    }

    return (
      <Space>
        <ShowPaymentModalButton recordItemId={record.id} />
        {canDeletePayment && (
          <DeleteButton size="small" recordItemId={record.id} hideText />
        )}
      </Space>
    );
  };

  const start = async () => {
    setIsTableLoading(true);
  };

  const finish = async () => {
    setIsTableLoading(false);
    setSelectedRowKeys([]);
  };

  const paymentFinish = () => {
    setIsTableLoading(false);
    setSelectedRowKeys([]);
    refetchPaymentTable();
  };

  const deleteMany = async () => {
    setIsTableLoading(true);

    interface PaymentResult {
      status: string;
      paymentId: number;
    }

    const paymentIDs = selectedRowData.map((payment) => payment.id);

    const deletePromises = paymentIDs.map(async (paymentId) => {
      try {
        await axiosInstance.delete(`${apiUrl}/payment/${paymentId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        return { status: "success", paymentId };
      } catch (error) {
        checkError(error);
        return { status: "error", paymentId };
      }
    });

    const results = await Promise.allSettled(deletePromises);

    results.forEach((result: PromiseSettledResult<PaymentResult>) => {
      if (result.status === "fulfilled") {
        const { value } = result as PromiseFulfilledResult<PaymentResult>;
        notification.success({
          message: `Pagamento ID: ${value.paymentId} excluído com sucesso!`,
        });
      } else {
        const { reason } = result as PromiseRejectedResult;
        notification.error({
          message: `Erro ao excluir pagamento ID: ${reason.paymentId}`,
        });
      }
    });

    await refetchPaymentTable();

    setSelectedRowKeys([]);
    setSelectedRowData([]);
    setIsTableLoading(false);
  };

  const PaymentHeaderPage: JSX.Element = (
    <HeaderPage>
      {isAdmin && (
        <Select
          options={bankOptions}
          defaultValue={bankId}
          onChange={setBankId}
        />
      )}
      <RefreshButton
        loading={isTableLoading}
        disabled={isRefreshingBalance || isReportLoading}
        onClick={handleRefreshBalance}
      >
        Atualizar
      </RefreshButton>
      <Button
        icon={<FileExcelOutlined />}
        loading={isReportLoading}
        disabled={isRefreshingBalance || isReportLoading}
        onClick={handleGenerateReport}
      >
        Gerar Relatório
      </Button>
      {canCreatePayment && (
        <Button icon={<UploadOutlined />} onClick={() => showImportPayments()}>
          Importar
        </Button>
      )}
      {hasSelected && isAllPending && (
        <PaymentSummaryModal
          data={selectedRowData}
          onStart={start}
          onFinish={paymentFinish}
        />
      )}
      {hasSelected && isPdf && (
        <Voucher
          data={selectedRowData}
          onDowloadStart={start}
          onDowloadFinish={finish}
        />
      )}
      {hasSelected && canDeleteMany && (
        <CustomDeleteButton
          body={selectedRowData.map((payment) => payment.id)}
          handleDelete={deleteMany}
        />
      )}
    </HeaderPage>
  );

  return (
    <TwoSections topElementTitle="Opções" topElement={PaymentHeaderPage}>
      <CustomList title="Pagamentos">
        <Table
          {...tableProps}
          rowKey="id"
          rowSelection={rowSelection}
          loading={tableProps.loading || isTableLoading}
          scroll={{ x: 1500 }}
          pagination={{
            ...tableProps.pagination,
            totalBoundaryShowSizeChanger: 10,
          }}
        >
          <Table.Column
            dataIndex={"id"}
            title="ID"
            width={100}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Filtrar por ID" />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"contract"}
            title="Contrato"
            ellipsis={true}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Filtrar por contrato" />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"payerName"}
            title="Nome"
            render={(value: string) => value.toUpperCase()}
            ellipsis={true}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Filtrar por nome" />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"payerFiscalId"}
            title="CPF/CNPJ"
            render={handleFiscalId}
            ellipsis={true}
            width={150}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Filtrar por CPF/CNPJ" />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"status"}
            title="Status"
            render={handleStatus}
            width={150}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  style={{ minWidth: 200, maxWidth: 250 }}
                  options={[
                    {
                      label: "Erro",
                      value: "error",
                    },
                    {
                      label: "Estornado",
                      value: "reversal",
                    },
                    {
                      label: "Indisponivel",
                      value: "unavailable",
                    },
                    {
                      label: "Pago",
                      value: "paid",
                    },
                    {
                      label: "Pendente",
                      value: "pending",
                    },
                    {
                      label: "Processando",
                      value: "processing",
                    },
                  ]}
                  placeholder="Status de pagamento"
                  mode="multiple"
                />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"operationType"}
            title="Tipo"
            width={130}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Select
                  style={{ minWidth: 200, maxWidth: 250 }}
                  options={[
                    {
                      label: "BOLETO",
                      value: "boleto",
                    },
                    {
                      label: "PIX",
                      value: "pix",
                    },
                    {
                      label: "PIX QR CODE",
                      value: "pixqrcode",
                    },
                    {
                      label: "TED",
                      value: "ted",
                    },
                  ]}
                  placeholder="Tipo de operação"
                  mode="multiple"
                />
              </FilterDropdown>
            )}
          />
          <Table.Column
            dataIndex={"createdAt"}
            title={<div style={{ whiteSpace: "nowrap" }}>Data criação</div>}
            render={handleDate}
            width={150}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <DatePicker.RangePicker locale={locale} format={"DD/MM/YYYY"} />
              </FilterDropdown>
            )}
          />
          <Table.Column
            title={<div style={{ whiteSpace: "nowrap" }}>Valor devido</div>}
            dataIndex={"dueAmount"}
            render={(value) => (value ? `${convertToBRL(value)}` : "****")}
            ellipsis={true}
          />
          <Table.Column
            dataIndex={"payerAccountBalance"}
            title={<div style={{ whiteSpace: "nowrap" }}>Saldo conta</div>}
            render={handleAccountBalance}
            ellipsis={true}
          />
          <Table.Column
            dataIndex={"batchName"}
            title={"Lote"}
            render={(value) => value ?? "(vazio)"}
            ellipsis={true}
            sorter={true}
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input placeholder="Filtrar por Lote" />
              </FilterDropdown>
            )}
          />
          <Table.Column<IPayment>
            title="Ações"
            dataIndex="actions"
            render={handleActions}
          />
        </Table>
        <ImportPaymentDrawer
          createDrawerProps={importPaymentsDrawerProps}
          createFormProps={importPaymentsFormProps}
          apiURL={apiUrl}
          onChange={onSelectPaymentImportFile}
          bankId={bankId || ""}
        />
        {ShowPaymentModal}
        <FileErrorsModal
          visible={visibleFileErrorsMessages}
          onCancel={() => {
            setVisibleFileErrorsMessages(false);
          }}
          messages={fileErrorsMessages}
        />
      </CustomList>
    </TwoSections>
  );
};
